import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'app/ordini', pathMatch: 'full' },
  { path: 'app', redirectTo: 'app/ordini', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./auth/registrazione/registrazione.module').then(m => m.RegistrazionePageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./auth/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'app',
    canLoad: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
        canLoad: [AuthGuard]
      },
      {
        path: 'ordini',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/ordini/ordini.module').then(m => m.OrdiniPageModule),
            canLoad: [AuthGuard]
          },
          {
            path: ':orderId',
            pathMatch: 'prefix',
            loadChildren: () => import('./pages/ordini/ordine-details/ordine-details.module').then(m => m.OrdineDetailsPageModule),
            canLoad: [AuthGuard]
          }
        ]
      },
      {
        path: 'clienti',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/clienti/clienti.module').then(m => m.ClientiPageModule),
            canLoad: [AuthGuard]
          },
          // {
          //   path: ':clienteId',
          //   pathMatch: 'prefix',
          //   loadChildren: () => import('./pages/clienti/cliente-details/cliente-details.module').then(m => m.ClienteDetailsPageModule),
          //   canLoad: [AuthGuard]
          // }
        ]
      },
      {
        path: 'agenti',
        loadChildren: () => import('./pages/agenti/agenti.module').then(m => m.AgentiPageModule),
        canLoad: [AuthGuard]
      },
      {
        path: 'oos',
        loadChildren: () => import('./pages/out-of-stock/out-of-stock.module').then(m => m.OutOfStockPageModule),
        canLoad: [AuthGuard]
      },
      {
        path: 'prodotti',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/prodotti/prodotti.module').then(m => m.ProdottiPageModule),
            canLoad: [AuthGuard]
          },
          {
            path: ':productId',
            pathMatch: 'prefix',
            loadChildren: () => import('./pages/prodotti/prodotti-form/prodotti-form.module').then(m => m.ProdottiFormPageModule),
            canLoad: [AuthGuard]
          }
        ]
      }
    ]

  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
