export class User {
  constructor(
    private accessToken: string,
    private refreshToken: string,
    public userName: string,
    public firstName: string,
    public lastName: string,
    public roles: string[],
    public tokenExpirationDate: Date
  ) {}

  get token() {
    if (!this.tokenExpirationDate || this.tokenExpirationDate <= new Date()) {
      return null;
    }
    return this.accessToken;
  }

  get tokenDuration() {
    if (!this.token) {
      return 0;
    }
    return this.tokenExpirationDate.getTime() - new Date().getTime();
  }
}
