import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Utility} from '../objects/Utility';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const hostname = (Utility.isOrdino()) ? environment.hostNameOrdino : environment.hostNameNro;
        const newReq = req.clone({
            url: hostname + '/' + req.url
        });
        return next.handle(newReq);
    }
}
