import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, from } from 'rxjs';
import { map, tap, take, switchMap } from 'rxjs/operators';
import { Plugins } from '@capacitor/core';

import { appSettings } from '../app-settings';
import { User } from '../shared/models/user.model';
import { RegistrationUserInfo } from '../shared/models/registration-user.model';
import { UserProfileInfo } from '../shared/models/user-profile.model';
import { ResetPasswordInfo } from '../shared/models/reset-password.model';
import { ChangePasswordInfo } from '../shared/models/change-password.model';
import * as moment from 'moment';
import {Utility} from '../objects/Utility';

export interface AuthResponseData {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  userName: string;
  firstName: string;
  lastName: string;
  roles: string[];
  '.issued': Date;
  '.expires': Date;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private _user = new BehaviorSubject<User>(null);
  private activeLogoutTimer: any;

  get userIsAuthenticated() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return !!user.token;
        } else {
          return false;
        }
      })
    );
  }

  get userId() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.userName.toLowerCase();
        } else {
          return null;
        }
      })
    );
  }

  get token() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.token;
        } else {
          return null;
        }
      })
    );
  }

  get user() {
    return this._user.asObservable();
  }

  get isAdmin() {
    return this._user.value.roles.includes('Admin') || this._user.value.roles.includes('Sede');
  }

  constructor(private http: HttpClient) { }

  autoLogin() {
    return from(Plugins.Storage.get({ key: Utility.getKeyStorage() })).pipe(
      map(storedData => {
        if (!storedData || !storedData.value) {
          return null;
        }

        const user = JSON.parse(storedData.value) as {
          accessToken: string,
          refreshToken: string,
          userName: string,
          firstName: string,
          lastName: string,
          roles: string[],
          tokenExpirationDate: Date
        };
        const expirationTime = new Date(user.tokenExpirationDate);

        if (expirationTime <= new Date()) {
          return null;
        }

        return new User(user.accessToken, user.refreshToken, user.userName, user.firstName, user.lastName, user.roles, expirationTime);
      }),
      tap(user => {
        if (user) {
          this._user.next(user);
          this.autoLogout(user.tokenDuration);
        }
      }),
      map(user => {
        return !!user;
      })
    );
  }

  register(registerInfo: RegistrationUserInfo) {
    return this.http
      .post<AuthResponseData>(
        appSettings.API.REGISTER,
        registerInfo
      );
  }

  login(email: string, password: string) {
    let httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    let urlSearchParams = new URLSearchParams();
    urlSearchParams.set('grant_type', 'password');
    urlSearchParams.set('username', email);
    urlSearchParams.set('password', password);

    let body = urlSearchParams.toString();

    return this.http
      .post<AuthResponseData>(
        appSettings.API.TOKEN,
        body,
        {
          headers: httpHeaders
        }
      )
      .pipe(tap(this.setUserData.bind(this)));
  }

  changePassword(changepasswordInfo: ChangePasswordInfo) {
    let fetchedUserId: string;

    return this.userId.pipe(
      take(1),
      switchMap(userId => {
        fetchedUserId = userId;
        return this.token;
      }),
      take(1),
      switchMap(token => {
        if (!fetchedUserId) {
          throw new Error('No user found!');
        }

        return this.http.post(appSettings.API.CHANGEPWD, changepasswordInfo,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      }),
    );
  }

  forgotPassword(email: any) {
    let httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'application/json');

    return this.http.post(appSettings.API.FORGOTPWD, email, {
      headers: httpHeaders
    });
  }

  resetPassword(resetInfo: ResetPasswordInfo) {
    let httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'application/json');

    return this.http.post(appSettings.API.RESETPWD, resetInfo, {
      headers: httpHeaders
    });
  }

  logout() {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
    // this._user.next(null);
    Plugins.Storage.remove({ key: Utility.getKeyStorage() });
  }

  ngOnDestroy() {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
  }

  private autoLogout(duration: number) {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
    this.activeLogoutTimer = setTimeout(() => {
      this.logout();
    }, duration);
  }

  private setUserData(userData: AuthResponseData) {
    const expirationTime = new Date(
      new Date().getTime() + +userData.expires_in * 1000
    );
    const user = new User(
      userData.access_token,
      userData.refresh_token,
      userData.userName,
      userData.firstName,
      userData.lastName,
      userData.roles,
      expirationTime
    );
    
    this._user.next(user);
    this.autoLogout(user.tokenDuration);
    this.storeAuthData(user);
  }

  private storeAuthData(userData: User) {
    const data = JSON.stringify(userData);
    Plugins.Storage.set({ key: Utility.getKeyStorage(), value: data });
  }
}
