export const appSettings = {
    Configuration: {
        APP_TITLE: 'Ordino',
    },
    API: {
        TOKEN: `Token`,
        API: `api`,
        REGISTER: `api/account/register`,
        CHANGEPWD: `api/account/changepassword`,
        FORGOTPWD: `api/account/forgotpassword`,
        RESETPWD: `api/account/resetpassword`,
        CHECKACTIVE: `api/account/checkactive`,
        CHANGEPROFILE: `api/account/changeuserprofile`,
        CLIENTI: `api/clienti`,
        AGENTI: `api/agenti`,
        CATEGORIE: `api/categorieprodotti`,
        BRAND: `api/brandprodotti`,
        PRODOTTI: `api/prodotti`,
        ORDINI: `api/ordini`,
        TIPOLOGIEPAGAMENTO: `api/tipologiapagamento`,
        REPORTS: `api/reports`,
        PARSER: `api/Parser`
    },
    DOCS: {
        GDPR_TEMPLATE: `Templates/Docs/Privacy_Statement_Understanding_OSB_ORDINO.PDF`,
        TERMS_TEMPLATE: `Templates/Docs/CONTRATTO_DI_CONCESSIONE_IN_USO_DEL_SERVIZIO_ORDINO.PDF`
    }
};
