export class Utility {
    static isOrdino() {
        return window.location.href.includes('//ordino');
    }

    static isNro() {
        return window.location.href.includes('//mynro') || window.location.href.includes('http://localhost:4200/') || window.location.href.includes('http://localhost:8100/');
    }

    static getKeyStorage(): string {
        if (Utility.isOrdino()) {
            return 'ordinoAuthData';
        } else {
            return 'mynroAuthData';
        }
    }
}
