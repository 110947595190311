import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable()
export class ToastService {

  constructor(private toastCtrl: ToastController) { }

  presentToast(message: string) {
    let toast = this.toastCtrl.create({
      'duration': 3000,
      position: 'bottom',
      message: message,
      color: 'primary'
    });

    toast.then(toastRef => toastRef.present());
  }

  resolveError(error) {
    let errorMessage = '';

    try {
      if (error.status && error.status == 401) {
        errorMessage = 'Autenticazione Fallita!'
      } else if (error && error.status && error.status == '404') {
        errorMessage = 'La risorsa desiderata non è stata trovata!';
      } else if (error && error.error && error.error.Message) {
        errorMessage = error.error.Message;
      } else if (error && error.error && error.error.ModelState && error.error.ModelState[""] && error.error.ModelState[""][0]) {
        errorMessage = error.error.ModelState[""][0];
      } else if (error && error.error && error.error.error_description) {
        errorMessage = error.error.error_description;
      } else if (error.error.statusText) {
        errorMessage = error.error.statusText;
      } else if (error && error.error && typeof (error.error) === 'string') {
        errorMessage = error.error;
      } else {
        if (error.name == 'HttpErrorResponse') {
          errorMessage = 'Errore di comunicazione. Connessione limitata o assente!'
        } else {
          errorMessage = 'Errore!'
        }
      }

      return errorMessage;
    } catch {
      return 'Errore sconosciuto!';
    }
  }
}
