import { Injectable } from "@angular/core";
@Injectable()
export class ValidationHelper {

  constructor() { }

  isMail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  isValidPassword(password: string) {
    var re = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(password);
  }

  isValidDate(date: string) {
    var re = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    return re.test(date);
  }

  containsUpperChar(password: string) {
    var re = /\w*[A-Z]\w*/;
    return re.test(password);
  }

  containsNumber(password: string) {
    var re = /\w*[0-9]\w*/;
    return re.test(password);
  }

  containsSpecial(password: string) {
    var re = /\w*[@$!%*?&]\w*/;
    return re.test(password);
  }


}


