import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CheckUpdateService {

  constructor(public updates: SwUpdate, public alertCtrl: AlertController) {
    const timePick = 60*1000;

    if (updates.isEnabled) {
      interval(timePick).subscribe(() => updates.checkForUpdate()
        .then(() => console.log('Checking for updates...')));
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe(event => this.updateApp());
  }

  updateApp() {
    this.alertCtrl.create({
      header: 'Ordino è stata aggiornata!',
      backdropDismiss: false,
      message: `Premere OK per confermare.`,
      buttons: [
        {
          text: 'Ok',
          handler: (data) => {
            // window.location.reload();
            console.log('Updating to new version...');
            this.updates.activateUpdate().then(() => window.location.reload());
          }
        }
      ]
    }).then(ElRef => ElRef.present());
  }
}
